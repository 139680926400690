import { useState } from "react"
import PropTypes from "prop-types"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { Box, Grid, Tab, Tabs } from "@mui/material"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"

// ** Custom
import Header from "../layout/Header"
import WarehouseRequestTabView from "../components/WarehouseRequestTabView"
import TransferRequestTabView from "../components/TransferRequestTabView"

const DashboardView = ({
  selectedGlobalProgramId,
  allWRTData,
  handleEditFormSubmit,
  handleSkipHazmat,
  handleReopenOrCancelWRT,
  leftMenuDrawerOpen,
}) => {
  const title = "Pending Forms"
  const [tabValue, setTabValue] = useState(JSON.parse(localStorage.getItem("dashboardTabView")) || 0)

  const handleChange = (event, newValue) => {
    localStorage.setItem("dashboardTabView", JSON.stringify(newValue))
    setTabValue(newValue)
  }

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }

  const allyProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  return (
    <>
      <Header title={title} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            centered
          >
            <Tab
              label="Warehouse Requests"
              {...allyProps(0)}
            />
            <Tab
              label="Transfer Requests"
              {...allyProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={tabValue}
          index={0}
        >
          <WarehouseRequestTabView
            selectedGlobalProgramId={selectedGlobalProgramId}
            allWRTData={allWRTData}
            handleEditFormSubmit={handleEditFormSubmit}
            handleSkipHazmat={handleSkipHazmat}
            handleReopenOrCancelWRT={handleReopenOrCancelWRT}
            leftMenuDrawerOpen={leftMenuDrawerOpen}
            title={title}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={tabValue}
          index={1}
        >
          <TransferRequestTabView selectedGlobalProgramId={selectedGlobalProgramId} />
        </CustomTabPanel>
      </Box>
    </>
  )
}

export default DashboardView
