import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// * MUI
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ArchiveIcon from "@mui/icons-material/Archive"
import AssessmentIcon from "@mui/icons-material/Assessment"
import EditIcon from "@mui/icons-material/Edit"
import InfoIcon from "@mui/icons-material/Info"
import ListIcon from "@mui/icons-material/List"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Custom
import AppContext from "../AppContext"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { CustomModal } from "../components/CustomModal"
import CustomProgressDialog from "../components/CustomProgressDialog"
import Header from "../layout/Header"
import LoadingBackdrop, { getWarehouseBuildingNumber, notify, triggerDownload } from "../utils"
import "../components/styles/AdditionalColumnStyles.css"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { WarehouseAssignmentModal } from "../components/WarehouseAssignmentModal"

const ArmyProgramsView = ({ leftMenuDrawerOpen, userProgramsList, handleUpdateUserPrograms }) => {
  const context = useContext(AppContext)
  const [loaded, setLoaded] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [data, setData] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWarehouseAssignmentModalOpen, setIsWarehouseAssignmentModalOpen] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState("")
  const [selectedDeleteId, setSelectedDeleteId] = useState("")
  const location = useLocation()
  const [pathname, setPathname] = useState("")
  const title = "Programs"

  useEffect(() => {
    fetchData()
  }, [userProgramsList])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data])

  const fetchData = async () => {
    if (userProgramsList) {
      setData(userProgramsList)
    }
    generateBlankArmyProgramsTemplate()
    setPathname(location.state?.path || window.location.pathname)
  }

  const generateBlankArmyProgramsTemplate = () => {
    const blankTemplate = context.army_programs.formFields.reduce((acc, currVal) => {
      if (!acc[currVal.field]) {
        acc[currVal.field] = ""
      }
      return acc
    }, {})
    context.blankArmyProgramsTemplate = blankTemplate
  }

  const handleApDelete = async (id, path) => {
    try {
      let deleteResp = await apiCalls.patchRecord(id, path, "ActiveState", "replace", false)
      await handleUpdateUserPrograms(deleteResp.status, id)
      setSelectedDeleteId("")
      notify("success", `${title} item archived successfully.`)
    } catch (error) {
      console.error("ERROR:", error)
      notify("error", "There was a problem archiving your item. Please try again.")
    }
  }

  const updateColumns = context.army_programs.columns.map((el) => {
    if (el.field === "actions") {
      el.getActions = (params) => {
        if (context.isAdmin) {
          return [
            <Stack showInMenu>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                component={Link}
                to={`${pathname}/edit/${params.id}`}
                state={{ title: title }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<ArchiveIcon />}
                label="Archive"
                onClick={() => {
                  toggleModal()
                  setSelectedDeleteId(params.row)
                }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<PlaylistAddIcon />}
                label="WH Assignment"
                onClick={() => {
                  setSelectedProgram(params.row)
                  toggleWarehouseAssignmentModal()
                }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<ListIcon />}
                label="Warehouses"
                component={Link}
                to={`/list_view/${params.id}`}
                state={{ isFromAP: true }}
                showInMenu
              />
            </Stack>,
          ]
        } else {
          return [
            <Stack showInMenu={true}>
              <GridActionsCellItem
                icon={<ListIcon />}
                label="Warehouses"
                component={Link}
                to={`/list_view/${params.id}`}
                state={{ isFromAP: true, pathname: pathname }}
                showInMenu={true}
              />
            </Stack>,
          ]
        }
      }
    }
    return el
  })

  const toggleModal = () => {
    setIsModalOpen(true)
  }

  const toggleWarehouseAssignmentModal = () => {
    setIsWarehouseAssignmentModalOpen(true)
  }

  const handleConfirmDelete = (response) => {
    if (response) {
      handleApDelete(selectedDeleteId.id, "ArmyPrograms")
    }
    setIsModalOpen(false)
  }

  // Handles warehouse assignments for a program.
  const handleConfirmAction = async (response, action, value) => {
    if (response) {
      try {
        if (action === "unassign") {
          let deleteResponse = await apiCalls.deleteRecord(`${value}/${selectedProgram.id}`, "ManagementRecords")
          if (deleteResponse.status === 204)
            notify("success", `${getWarehouseBuildingNumber(value, context.warehouseData)} has been unassigned from ${selectedProgram.name}.`)
        } else {
          await Promise.all(
            value.map(async (f) => {
              let request = {
                armyProgramId: selectedProgram.id,
                warehouseId: f.id,
              }

              await apiCalls.postRecord(request, "ManagementRecords")
            })
          ).then(() => {
            let buildingNumbersList = value.map((f) => f.buildingNumber).join(", ")
            notify(
              "success",
              `The following warehouse(s) ${value.length > 1 ? "have" : "has"} been assigned to ${selectedProgram.name}: ${buildingNumbersList}.`
            )
          })
        }
      } catch (error) {
        console.log("ERROR:", error)
        notify("error", "A problem occurred with the warehouse assignment(s). Please try again.")
      }
    }

    setIsWarehouseAssignmentModalOpen(false)
    setSelectedProgram("")
  }

  const handleReportGeneration = async () => {
    try {
      setIsGenerating(true)
      await apiCalls.generateExcelFile("InventoryCompletionReport").then((response) => {
        setIsGenerating(false)
        const file = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        const url = window.URL.createObjectURL(file)
        triggerDownload(url, response.headers["content-disposition"].split('filename="')[1].split('";')[0])
        window.URL.revokeObjectURL(url)
      })
    } catch (error) {
      console.error("ERROR:", error)
      notify("error", "There was a problem generating the report. Please try again.")
    }
  }

  if (loaded) {
    return (
      <>
        <Header title={title} />
        {isGenerating && (
          <CustomProgressDialog
            open={isGenerating}
            progress={null}
            title="Generating Report..."
          />
        )}
        {isModalOpen && (
          <CustomModal
            shouldOpen={true}
            handleConfirmDelete={handleConfirmDelete}
            pathname={pathname}
            title="Confirm Program Archive"
            reference={selectedDeleteId.name}
          />
        )}
        {isWarehouseAssignmentModalOpen && (
          <WarehouseAssignmentModal
            shouldOpen={true}
            selectedProgram={selectedProgram}
            handleConfirmAction={handleConfirmAction}
          />
        )}

        {/* ADD PROGRAM BUTTON */}
        <div className="basic-btn-container">
          <div className="basic-btn-wrapper">
            {context.isAdmin && (
              <Link
                to={`${pathname}/new`}
                state={{ pathname: pathname, title: title }}
              >
                <Button
                  sx={{ marginRight: "10px", fontSize: ".87rem", fontWeight: "400", width: "12em" }}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Add Program
                </Button>
              </Link>
            )}

            {/* GENERATE INVENTORY COMPLETION REPORT BUTTON */}
            {(context.isAdmin || context.isInventoryManager) && (
              <>
                <Button
                  sx={{ fontSize: ".87rem", fontWeight: "400", width: "12em", whiteSpace: "nowrap" }}
                  variant="contained"
                  startIcon={<AssessmentIcon />}
                  onClick={handleReportGeneration}
                >
                  Generate Report
                </Button>
                <Tooltip
                  title={
                    <Typography
                      fontSize={13}
                      textAlign="left"
                    >
                      Generates an <i>Inventory Completion Report</i> with inventory from all <u>non-parent programs</u> listed in the table below.
                    </Typography>
                  }
                  arrow
                  placement="right"
                >
                  <IconButton style={{ backgroundColor: "transparent" }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </div>

        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <CustomStripedGrid
          pathname={pathname}
          data={data}
          columns={updateColumns}
          title={title}
        />
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default ArmyProgramsView
